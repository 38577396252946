  import React, { useRef, useEffect } from 'react';
  import { graphql } from 'gatsby';
  import { StaticImage } from 'gatsby-plugin-image';
  import PropTypes from 'prop-types';
  import { Helmet } from 'react-helmet';
  import styled from 'styled-components';
  import { srConfig } from '@config';
  import sr from '@utils/sr';
  import { Layout } from '@components';
  import { usePrefersReducedMotion } from '@hooks';

  const StyledTableContainer = styled.div`
    margin: 100px -20px;

    @media (max-width: 768px) {
      margin: 50px -10px;
    }

    table {
      font-size: 25px;
      width: 100%;
      border-collapse: collapse;

      .hide-on-mobile {
        @media (max-width: 768px) {
          display: none;
        }
      }

      tbody tr {
        &:hover,
        &:focus {
          background-color: var(--light-navy);
        }
      }

      tbody tr th {
        font-weight: normal;
      }

      th,
      td {
        padding: 10px;
        text-align: left;

        &:first-child {
          padding-left: 20px;

          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }
        &:last-child {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      tr {
        
        cursor: default;

        td:first-child {
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        td:last-child {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }

      td {
        &.year {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
            font-size: var(--fz-sm);
          }
        }

        &.title {
          padding-top: 15px;
          padding-right: 20px;
          color: var(--lightest-slate);
          font-size: var(--fz-xl);
          font-weight: 600;
          line-height: 1.25;
        }

        &.company {
          font-size: var(--fz-lg);
          white-space: nowrap;
        }

        &.tech {
          font-size: var(--fz-xxs);
          font-family: var(--font-mono);
          line-height: 1.5;
          .separator {
            margin: 0 5px;
          }
          span {
            display: inline-block;
          }
        }

        &.links {
          min-width: 100px;

          div {
            display: flex;
            align-items: center;

            a {
              ${({ theme }) => theme.mixins.flexCenter};
              flex-shrink: 0;
            }

            a + a {
              margin-left: 10px;
            }
          }
        }
      }
    }
  `;

  const StyledAboutSection = styled.section`

  counter-reset: section;

  max-width: 1400px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .numbered-heading {
    font-size: 42px;
    font-weight: bold;

    &:before {
      counter-increment: section 1;
      content: '0' counter(section) '.';
      font-size: clamp(var(--fz-md), 3vw, 24px);
      font-weight: bold;
    }

    &:after {
      width: 400px;
    }
  }

  
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  width: 700px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }
    }


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

  const ArchivePage = ({ location, data }) => {
    const projects = data.allMarkdownRemark.edges;
    const revealTitle = useRef(null);
    const revealTable = useRef(null);
    const revealProjects = useRef([]);
    const prefersReducedMotion = usePrefersReducedMotion();

    useEffect(() => {
      if (prefersReducedMotion) {
        return;
      }

      sr.reveal(revealTitle.current, srConfig());
      sr.reveal(revealTable.current, srConfig(200, 0));
      revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
    }, []);

    return (
      <Layout location={location}>
        <Helmet title="Archive" />

        <main>
          <header ref={revealTitle}>
            <h1 className="big-heading">ZoomVerse</h1>
            <p className="subtitle" style={{fontSize:"22px"}}>A Zoom derived office product where people can stay connected by immersive virtual space.</p>
          </header>

          <StyledTableContainer ref={revealTable}>
            <table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Team Members</th>
                  <th className="hide-on-mobile">My Role</th>
                  <th className="hide-on-mobile">Duration</th>
                  <th>Built with</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2022</th>
                  <th>Yifan Wang, Colette Chen, <br/> Lich Tong, Guifu Luo</th>
                  <th>UX Designer / Researcher, <br/> Design Prototyping</th>
                  <th>10 Weeks</th>
                  <th>Figma, Miro</th>
                </tr>
              </tbody>
            </table>
          </StyledTableContainer>

          <StyledAboutSection id="about" ref={revealTable}>
        <h2 className="numbered-heading">Overview</h2>
        
        <div className="inner">
          <StyledText>
            <div>
              <h3><b>" A immersive online experience. "</b></h3>

              <br/>

              <h2><b>Goal of the Project</b></h2>

              <h3>
              The covid pandemic has shaped the ways people work.
              <br/>
              People use video communication products frequently during the WFH Era.
              However, using video communication products are less immersive compared to the on-site experience.
              <br/><br/>
              <b>People miss the collaborative and interactive on-site experience.</b>
              </h3>

              <br/>

              <h2><b>Solution</b></h2>

              <h3>
              ZoomVerse is a Zoom extension product where people can stay connected by immersive virtual space.
              </h3>

              <br/>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/zoomverse overview.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/>

        {/* Section 02 - Initial Research */}

        <h2 className="numbered-heading" >Initial Research</h2>

        <div className="inner">

        <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/NNG 1.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledText>
            <br/><br/>
            <div>
              <h2>The initial research focused on understanding how telecommunication products work. 
                <br/><br/>
                We summarized the common features and evaluated the user experience through NNG Heuristics Analysis.</h2>

                <br/>

            <StaticImage
                className="img"
                src="../images/zoomverse/Group 331.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />

            </div>
          </StyledText>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - Primary Research */}

        <h2 className="numbered-heading" >Primary Research</h2>

        <div className="inner">
          <StyledText>
            <div>

            <br/><br/><br/><br/><br/><br/><br/><br/>

              <h2 style={{fontSize: "33px"}}><b>Competitor Analysis</b></h2>

              <br/>

              <h2>
              We conduct competitor analysis on the immersive virtual space meeting products' functionality: 
              <br/><br/>
              - Wonder.me
              <br/>
              - Spatial Chat
              <br/>
              - Topia
              <br/>
              - GatherTown
              </h2>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/competitor analysis.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/>

        <div className="inner">

        <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/interview insights.jpg"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledText>
            <div>

            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

              <h2 style={{fontSize: "33px"}}><b>Interview Insights</b></h2>

              <br/>

              <h2>
              We conduct <b>10 one-to-one interviews </b> with people who had experiences using virtual space video meeting products during work or conferences.
              </h2>

            </div>

          </StyledText>
        </div>

        <br/><br/><br/><br/>

        <div className="inner">
          <StyledText>
            <div>

            <br/><br/>

              <h2 style={{fontSize: "33px"}}><b>User Journey Map</b></h2>

              <br/>

              <h2>
              To better understand the current Zoom user experience and brainstorm for Zoom virtual space, 
              we used a user journey map to map out pain points that users have when using Zoom.
              </h2>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/User Journey.png"
                width={800}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - `Ideat`e */}

        <h2 className="numbered-heading" >Ideate - Affinity Diagram & Main Page Ideation</h2>

        <div className="inner">

        <StyledPic style={{width: "550px"}}>
            <br/><br/><br/>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/ideation.png"
                width={550}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledPic style={{width: "550px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/affinity.png"
                width={550}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 04 - Finalized Features */}

        <h2 className="numbered-heading" >Finalized Features</h2>

        <div className="inner">

        <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/feature summary.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledText>
            <br/>
            <div>
              <h2>
                Upon discussion, we named our product <b>ZoomVerse</b> with 6 features to be designed: 
                <br/><br/>
                ‣ Onboarding<br/>‣ Avatar<br/> ‣ Chat Function<br/> ‣ View Participant<br/> ‣ Build Space <br/> ‣ Add Media
              </h2>
            </div>
          </StyledText>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 05 - Hi-Fi Prototype Solution */}

        <h2 className="numbered-heading" >Hi-Fi Prototype Solution</h2>

        {/* Feature 1 */}

        <div className="inner">

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/Avator 2.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Feature 1 - Customize Your Avatar!</b></h2>
                <br/>
                <h2>  ‍‣ Customize your avatar by choosing the color and accessories <br/><br/> 
                ‍‣ Click the avatar to chage status and send emojis</h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 2 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/><br/><br/><br/><br/><br/>

              <div>
              
                <h2 style={{fontSize: "33px"}}><b>Feature 2 - 
                  <br/>
                   the onboarding guide!</b>
                </h2>
                <br/>
                <h2> ‣ Walk through the onboarding guide‍ to learn more about the ZoomVerse space! </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "850px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/onboarding.png"
                  width={850}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/>

        {/* Feature 3 */}

        <div className="inner">

            <StyledPic style={{width: "850px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/feature 2 build.png"
                  width={850}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/>
              
              <div>
                <br/><br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Feature 3 - Let's Build Your Own Space!</b></h2>
                <br/>
                <h2>  ‍‣ Pick a season <br/><br/> ‍‣ Customize the space</h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 4 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Feature 4 - Let's Chat in the Space!</b></h2>
                <br/>
                <h2>  ‍‣ Send text messages to everyone, nearby people, or someone privately <br/><br/> ‍‣ Chatbox with live messages and information</h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "850px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/feature 3 Chat.png"
                  width={850}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/>

        {/* Feature 5 */}

        <div className="inner">

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/feature 4 participants.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/><br/><br/>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Feature 5 - Let's View Other Participants in the space!</b></h2>
                <br/>
                <h2>  ‍‣ View participants’ status and information <br/><br/> ‍‣ View meeting rooms in the space </h2>
              </div>
            </StyledText>
            
        </div>
        
        <br/><br/><br/>

        {/* Feature 6 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Feature 6 - Add your media to the space!</b></h2>
                <br/>
                <h2>  ‍‣ Choose an interactive object <br/><br/> ‍‣ Drop or add media, the media object appears in the space</h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "850px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/zoomverse/feature 5 add media.png"
                  width={850}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Video Prototype</b></h2> 

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

          <StyledPic style={{width: "1300px"}}>
            <iframe width = "1300px" height = "700px" frameborder="0"
              src="https://www.youtube.com/embed/xVvQNlVeEGI">
            </iframe>
          </StyledPic>

        </div>       
        
        <br/><br/><br/><br/><br/>

        {/* Section 06 - Innovations and Backgrounds */}

        <h2 className="numbered-heading" >Innovations and Backgrounds</h2>

        <div className="inner">

        <StyledPic style={{width: "600px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/innovation image 1.png"
                width={600}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledPic style={{width: "600px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/innovation image 2.jpg"
                width={600}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
          </div>

          <br/> <br/> <br/>

          <div className="inner">
          <StyledPic style={{width: "1250px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/zoomverse/overall.png"
                width={1250}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/><br/>

        <h2 className="numbered-heading" >Challenge & Key Takeaways</h2>

        <div>

        <h2 style={{fontSize: "33px"}}><b>Integrating the Concept Together</b></h2>       
        <h2>MetaVerse and WFH are both popular and big concepts, we need to find a balance where we can incorporate those two into Zoom meetings. ZoomVerse is a brand new concept that we need to design from the beginning(Avatar, background, emojis, etc..).</h2>
        
        <br/>

        <h2 style={{fontSize: "33px"}}><b>New Design Innovation Experience</b></h2>
        <h2>I don’t have any experience in designing cartoon characters or drawing virtual backgrounds. Luckily, one group member has the certain experience and help us to design the avatar and background together.
</h2>
        <br/>
        
        <h2 style={{fontSize: "33px"}}><b>Limitation on Design System</b></h2>
        <h2>Zoom has its design system and we need to keep it the same for consistency. It limits us when we are creating avatars, backgrounds, and prototypes as the color and theme need to be consistent with Zoom. However, it is a good practice for us to practice incorporating new ideas into the design system.</h2>

        <br/>
        
        <h2 style={{fontSize: "33px"}}><b>Reflection on Team Work</b></h2>
        <h2>Our team members have his/her strength such as Figma, research, and cartoon design. We teach each other and make sure everyone agrees on each design idea. We summarized the design ideas and implement the final prototype together. I learned about how to create cartoon characters and draw user journey maps. I also share my experience with using Figma and research.</h2>

        </div>
        
      </StyledAboutSection>
        </main>
      </Layout>
    );
  };
  ArchivePage.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  export default ArchivePage;

  export const pageQuery = graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              tech
              github
              external
              company
            }
            html
          }
        }
      }
    }
  `;
